import type { JSX } from 'react';

import { withPrefetchedData } from 'src/shared/prefetch';

import { Homepage as HomepageV2 } from 'src/app/pages/homepageV2';
import { FCM_STARFISH_HOMEPAGE_V2 } from 'src/app/shared/fcm/configs';

import { Homepage } from './index';

type HomepageWrapperPrefetchedUserData = {
	prefetchedUserData: {
		newPage: boolean;
	};
};

export const HomepageWrapper = withPrefetchedData(
	function HomepageWrapper({ prefetchedUserData }: HomepageWrapperPrefetchedUserData): JSX.Element {
		if (prefetchedUserData?.newPage) {
			return <HomepageV2 />;
		}

		return <Homepage />;
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchDependencies: async (context) => {
			const userData = await context.getUserData();
			return userData?.newPage ? [HomepageV2] : [Homepage];
		},
		prefetchUserData: async (context) => {
			const fcm = await context.utilityContext.fcm.get({
				homepageV2: FCM_STARFISH_HOMEPAGE_V2,
			});

			if (!fcm.homepageV2) {
				return { newPage: false };
			}

			const experiment = context.utilityContext.experiments.get('starfish_homepage_v2');
			await experiment.treat();
			const newPage = experiment.variation === 'variant';

			return { newPage };
		},
	},
);
